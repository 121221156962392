import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { Clock, ThumbsUp, Share2, ChevronDown, DollarSign, TrendingUp, Shield } from 'lucide-react';

const ApuestasEnVivo = () => {
  const [scrollProgress, setScrollProgress] = useState(0);
  const [showToc, setShowToc] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const totalScroll = document.documentElement.scrollHeight - window.innerHeight;
      const currentScroll = window.pageYOffset;
      setScrollProgress((currentScroll / totalScroll) * 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const sections = [
    { id: 'intro', title: '¿Qué son las Apuestas en Vivo?' },
    { id: 'ventajas', title: 'Ventajas de Apostar en Vivo' },
    { id: 'pasos', title: 'Pasos para Apostar en Vivo' },
    { id: 'consejos', title: 'Consejos para Apostar en Vivo' },
    { id: 'conclusion', title: 'Conclusión' },
  ];

  return (
    <>
      <Helmet>
        <title>Domina las Apuestas en Vivo: Guía Definitiva 2024 | Sports Pro</title>
        <meta
          name="description"
          content="Descubre los secretos de las apuestas en vivo con nuestra guía definitiva. Estrategias avanzadas, consejos de expertos y las mejores plataformas para maximizar tus ganancias."
        />
        <meta name="keywords" content="apuestas en vivo, estrategias de apuestas, apuestas deportivas, guía experta, apuestas en tiempo real" />
        <meta property="og:title" content="Domina las Apuestas en Vivo: Guía Definitiva 2024" />
        <meta property="og:description" content="Estrategias avanzadas y consejos de expertos para dominar las apuestas en vivo y maximizar tus ganancias." />
        <meta property="og:image" content="https://example.com/apuestas-en-vivo-banner.jpg" />
        <meta property="og:type" content="article" />
        <link rel="canonical" href="https://sportspro.com/guias/apuestas-en-vivo-2024" />
      </Helmet>

      <article className="min-h-screen bg-gradient-to-br from-gray-900 to-blue-900 text-white">
        <motion.div
          className="fixed top-0 left-0 right-0 h-1 bg-green-500 z-50"
          style={{ width: `${scrollProgress}%` }}
        />

        <header className="relative h-screen flex items-center justify-center">
          <div className="absolute inset-0 bg-black opacity-50"></div>
          {/* <img 
            src="https://example.com/apuestas-en-vivo-banner.jpg" 
            // alt="Apuestas en Vivo" 
            className="absolute inset-0 w-full h-full object-cover"
          /> */}
          <div className="relative z-10 text-center px-4">
            <motion.h1 
              className="text-5xl md:text-7xl font-extrabold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-500"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Domina las Apuestas en Vivo
            </motion.h1>
            <motion.p 
              className="text-xl md:text-2xl mb-8"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2, duration: 0.5 }}
            >
              Guía Definitiva 2024
            </motion.p>
            <motion.button
              className="bg-green-500 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-green-600 transition duration-300"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => document.getElementById('content').scrollIntoView({ behavior: 'smooth' })}
            >
              Descubre los Secretos
            </motion.button>
          </div>
          <motion.div 
            className="absolute bottom-10 left-1/2 transform -translate-x-1/2"
            animate={{ y: [0, 10, 0] }}
            transition={{ repeat: Infinity, duration: 1.5 }}
          >
            <ChevronDown size={40} color="white" />
          </motion.div>
        </header>

        <main id="content" className="container mx-auto px-4 py-16">
          <div className="flex flex-col md:flex-row gap-8">
            <aside className="md:w-1/4">
              <div className="sticky top-20">
                <h2 className="text-2xl font-bold mb-4">Contenido</h2>
                <ul className="space-y-2">
                  {sections.map((section) => (
                    <li key={section.id}>
                      <a
                        href={`#${section.id}`}
                        className="text-blue-300 hover:text-blue-100 transition duration-300"
                      >
                        {section.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </aside>

            <div className="md:w-3/4">
              <section id="intro" className="mb-16">
                <h2 className="text-4xl font-bold mb-6">¿Qué son las Apuestas en Vivo?</h2>
                <p className="text-lg mb-4">
                  Las apuestas en vivo revolucionan la forma en que interactuamos con los eventos deportivos...
                </p>
                {/* Más contenido aquí */}
              </section>

              <section id="ventajas" className="mb-16">
                <h2 className="text-4xl font-bold mb-6">Ventajas de Apostar en Vivo</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                  <motion.div 
                    className="bg-gray-800 p-6 rounded-lg"
                    whileHover={{ scale: 1.05 }}
                  >
                    <DollarSign size={40} className="text-green-500 mb-4" />
                    <h3 className="text-xl font-semibold mb-2">Mayores Ganancias Potenciales</h3>
                    <p>Las cuotas dinámicas ofrecen oportunidades únicas para maximizar tus ganancias.</p>
                  </motion.div>
                  <motion.div 
                    className="bg-gray-800 p-6 rounded-lg"
                    whileHover={{ scale: 1.05 }}
                  >
                    <TrendingUp size={40} className="text-blue-500 mb-4" />
                    <h3 className="text-xl font-semibold mb-2">Análisis en Tiempo Real</h3>
                    <p>Toma decisiones informadas basadas en el desarrollo actual del evento.</p>
                  </motion.div>
                  <motion.div 
                    className="bg-gray-800 p-6 rounded-lg"
                    whileHover={{ scale: 1.05 }}
                  >
                    <Shield size={40} className="text-purple-500 mb-4" />
                    <h3 className="text-xl font-semibold mb-2">Mayor Control</h3>
                    <p>Ajusta tus estrategias sobre la marcha para minimizar riesgos.</p>
                  </motion.div>
                </div>
              </section>

              {/* Añade más secciones aquí siguiendo un patrón similar */}

              <section id="conclusion" className="mb-16">
                <h2 className="text-4xl font-bold mb-6">Conclusión</h2>
                <p className="text-lg mb-4">
                  Dominar las apuestas en vivo requiere práctica, disciplina y una comprensión profunda de las dinámicas del juego...
                </p>
                {/* Más contenido aquí */}
              </section>
            </div>
          </div>
        </main>

        <footer className="bg-gray-900 text-white py-12">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-between items-center">
              <div className="w-full md:w-1/3 mb-6 md:mb-0">
                <h3 className="text-2xl font-bold mb-4">Sports Pro</h3>
                <p>Tu fuente definitiva para apuestas deportivas inteligentes.</p>
              </div>
              <div className="w-full md:w-1/3 mb-6 md:mb-0">
                <h4 className="text-xl font-semibold mb-4">Enlaces Rápidos</h4>
                <ul className="space-y-2">
                  <li><a href="#" className="hover:text-green-500 transition duration-300">Inicio</a></li>
                  <li><a href="#" className="hover:text-green-500 transition duration-300">Guías</a></li>
                  <li><a href="#" className="hover:text-green-500 transition duration-300">Contacto</a></li>
                </ul>
              </div>
              <div className="w-full md:w-1/3">
                <h4 className="text-xl font-semibold mb-4">Síguenos</h4>
                <div className="flex space-x-4">
                  <a href="#" className="text-2xl hover:text-green-500 transition duration-300">
                    <i className="fab fa-facebook"></i>
                  </a>
                  <a href="#" className="text-2xl hover:text-green-500 transition duration-300">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="#" className="text-2xl hover:text-green-500 transition duration-300">
                    <i className="fab fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="mt-8 pt-8 border-t border-gray-800 text-center">
              <p>&copy; 2024 Sports Pro. Todos los derechos reservados.</p>
            </div>
          </div>
        </footer>
      </article>
    </>
  );
};

export default ApuestasEnVivo;