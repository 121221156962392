import React, { useEffect, useState } from "react";
import {
  MdMonetizationOn,
  MdAccountBalanceWallet,
  MdAddCard,
} from "react-icons/md";
import useUserData from "../Hooks/useUserData";
import { Link } from "react-router-dom";
import { IoAddCircleOutline, IoStorefront } from "react-icons/io5";
import FootballLoading from "./FootballLoading";

const CircularProgressReward = () => {
  const { userData, loading, error, updateUserData } = useUserData();
  const [progress, setProgress] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAppActive, setIsAppActive] = useState(true); // Nuevo estado para saber si la app está activa

  const handleReward = async () => {
    if (userData) {
      const newSaldo = (userData.saldo || 0) + 0.15;
      try {
        await updateUserData({ saldo: newSaldo });
        console.log("Saldo actualizado correctamente");
      } catch (error) {
        console.error("Error al actualizar el saldo:", error);
      }
    }
  };

  // Efecto para gestionar el progreso con la validación de si la app está activa
  useEffect(() => {
    let interval;
    if (isAppActive) {
      interval = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            handleReward();
            return 0;
          }
          return prevProgress + 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isAppActive, userData]);

  // Monitorizar si la pestaña está activa o no
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        setIsAppActive(true);
      } else {
        setIsAppActive(false);
      }
    };

    // Monitorizar si el usuario está en línea o fuera de línea
    const handleOnlineStatus = () => {
      setIsAppActive(navigator.onLine && document.visibilityState === "visible");
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOnlineStatus);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOnlineStatus);
    };
  }, []);

  if (loading) return <FootballLoading />;
  if (error) return <div>Error: {error}</div>;

  const menuItems = [
    { icon: MdAccountBalanceWallet, text: "Billetera", route: "/wallet" },
    { icon: IoStorefront , text: "Tienda", route: "/store" },
    // Otros elementos del menú...
  ];

  return (
    <div className="relative">
      <div
        className="flex items-center space-x-2 cursor-pointer"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <MdMonetizationOn className="text-yellow-500 text-xl" />
        <span className="text-white text-xl font-bold">
          {userData?.saldo || 0}
        </span>
        <div className="relative w-10 h-10">
          <div
            className="absolute inset-0 rounded-full border-4 border-gray-300"
            style={{
              background: `conic-gradient(
                #4ade80 ${progress * 3.6}deg,
                #ddd ${progress * 3.6}deg
                )`,
            }}
          ></div>
          <div className="absolute inset-0 flex items-center justify-center text-white text-sm font-bold">
            {progress}%
          </div>
        </div>
        <IoAddCircleOutline className="text-white text-xl" />
      </div>

      {isMenuOpen && (
        <div className="absolute top-full left-0 mt-2 w-48 bg-gray-800 rounded-md shadow-lg z-50">
          {menuItems.map((item, index) => {
            const Icon = item.icon;
            return (
              <Link
                key={index}
                to={item.route}
                className="flex items-center px-4 py-3 text-white hover:bg-gray-700 transition-colors duration-150 ease-in-out"
              >
                <Icon className="text-xl mr-3" />
                <span>{item.text}</span>
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CircularProgressReward;
