import React, { useState } from 'react';
import { FaFacebookF, FaWhatsapp, FaEnvelope } from 'react-icons/fa';
import { ref, push } from 'firebase/database'; // Firebase imports
import { database } from '../../Config/firebase'; // Import your Firebase config
import { BsTelephone, BsTelephoneFill } from 'react-icons/bs';

const ContactForm = () => {
  const [contactInfo, setContactInfo] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [successMessage, setSuccessMessage] = useState('');

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactInfo({ ...contactInfo, [name]: value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const contactRef = ref(database, 'App/contact'); // Reference to 'App/contact' node in Firebase
    push(contactRef, contactInfo)
      .then(() => {
        setSuccessMessage('¡Tu mensaje ha sido enviado con éxito!');
        setContactInfo({ name: '', email: '', message: '' });
      })
      .catch((error) => {
        console.error('Error al enviar el mensaje:', error);
      });
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-900 text-white p-4">
      <h2 className="text-3xl font-bold mb-6">Contáctanos</h2>

      <form onSubmit={handleSubmit} className="w-full max-w-md bg-gray-800 p-6 rounded-lg shadow-lg">
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2">Nombre</label>
          <input
            type="text"
            name="name"
            value={contactInfo.name}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 text-gray-900 rounded-md focus:outline-none"
            placeholder="Tu nombre"
          />
        </div>
        
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2">Correo</label>
          <input
            type="email"
            name="email"
            value={contactInfo.email}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 text-gray-900 rounded-md focus:outline-none"
            placeholder="Tu correo electrónico"
          />
        </div>

        <div className="mb-6">
          <label className="block text-sm font-bold mb-2">Mensaje</label>
          <textarea
            name="message"
            value={contactInfo.message}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 text-gray-900 rounded-md focus:outline-none"
            rows="4"
            placeholder="Escribe tu mensaje"
          />
        </div>

        <button type="submit" className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 rounded-md">
          Enviar
        </button>

        {successMessage && <p className="mt-4 text-green-400">{successMessage}</p>}
      </form>
        <p className='pt-5'>Otros Contactos</p>
      <div className="flex justify-center mt-6 space-x-6">
        <a href="mailto:deportivospro@gmail.com" className="text-white hover:text-gray-400">
          <FaEnvelope size={24} />
        </a>
        <a href="https://www.facebook.com/profile.php?id=61563265143068" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-gray-400">
          <FaFacebookF size={24} />
        </a>
        <a href="https://wa.me/573023580862" target="_blank" rel="noopener noreferrer" className="text-green-600 hover:text-gray-400">
          <FaWhatsapp size={24} />
        </a>
        <a href="tel:+573023580862" className="text-red-600 hover:text-gray-400">
            <BsTelephone  size={24} />
        </a>

      </div>
    </div>
  );
};

export default ContactForm;
