import { useEffect } from 'react';

const useAppSecurity = () => {
  useEffect(() => {
    // Bloquear el zoom con atajos de teclado y gestos táctiles
    const preventZoom = (e) => {
      if (e.ctrlKey || e.metaKey || e.key === 'Control' || e.key === '+') {
        e.preventDefault();
      }
    };
    document.addEventListener('keydown', preventZoom);
    document.addEventListener('wheel', (e) => {
      if (e.ctrlKey) e.preventDefault();
    });
    document.addEventListener('gesturestart', (e) => e.preventDefault());

    // Deshabilitar el scroll
    // document.body.style.overflow = 'hidden';

    // Prevenir captura de pantalla
    const preventPrintScreen = () => {
      document.addEventListener('keydown', (e) => {
        if (e.key === 'PrintScreen') {
          e.preventDefault();
          alert('Captura de pantalla deshabilitada.');
        }
      });
    };
    preventPrintScreen();

    // Prevenir copia de texto
    const preventCopy = (e) => e.preventDefault();
    document.addEventListener('copy', preventCopy);

    // Prevenir inspección de elementos y herramientas de desarrollo
    const preventDevTools = () => {
      document.addEventListener('keydown', (e) => {
        if (
          (e.ctrlKey && e.shiftKey && e.key === 'I') || // Ctrl + Shift + I (Inspector)
          (e.ctrlKey && e.shiftKey && e.key === 'C') || // Ctrl + Shift + C (Selector)
          (e.ctrlKey && e.shiftKey && e.key === 'J') || // Ctrl + Shift + J (Console)
          (e.ctrlKey && e.key === 'U')                  // Ctrl + U (View source)
        ) {
          e.preventDefault();
          alert('Herramientas de desarrollador deshabilitadas.');
        }
      });

      // Bloquear clic derecho (opción para inspeccionar)
      document.addEventListener('contextmenu', (e) => e.preventDefault());
    };
    preventDevTools();

    // Prevenir arrastrar y soltar texto o imágenes
    const preventDragAndDrop = () => {
      document.addEventListener('dragstart', (e) => e.preventDefault());
      document.addEventListener('drop', (e) => e.preventDefault());
    };
    preventDragAndDrop();

    // Prevenir selección de texto
    const preventTextSelection = () => {
      document.addEventListener('selectstart', (e) => e.preventDefault());
    };
    preventTextSelection();

    // Limpiar los eventos al desmontar el componente
    return () => {
      document.removeEventListener('keydown', preventZoom);
      document.removeEventListener('wheel', preventZoom);
      document.removeEventListener('gesturestart', preventZoom);
      document.body.style.overflow = 'auto';
      document.removeEventListener('copy', preventCopy);
      document.removeEventListener('keydown', preventDevTools);
      document.removeEventListener('contextmenu', preventDevTools);
      document.removeEventListener('dragstart', preventDragAndDrop);
      document.removeEventListener('drop', preventDragAndDrop);
      document.removeEventListener('selectstart', preventTextSelection);
    };
  }, []);
};

export default useAppSecurity;
