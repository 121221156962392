import React, { useState, useEffect } from 'react';
import { ref, onValue, push } from 'firebase/database';
import { XCircle } from 'lucide-react';
import { database } from '../Config/firebase';
import { getAuth } from 'firebase/auth';

const MessageAlerts = () => {
  const [activeMessage, setActiveMessage] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    const messagesRef = ref(database, 'Alerts');
    const unsubscribe = onValue(messagesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const closedMessageIds = JSON.parse(localStorage.getItem('closedMessageIds') || '[]');
        const activeMessageEntry = Object.entries(data).find(([id, msg]) => 
          msg.active === true && !closedMessageIds.includes(id)
        );
        if (activeMessageEntry) {
          setActiveMessage({ id: activeMessageEntry[0], ...activeMessageEntry[1] });
        } else {
          setActiveMessage(null);
        }
      } else {
        setActiveMessage(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleClose = () => {
    if (activeMessage) {
      const closedMessageIds = JSON.parse(localStorage.getItem('closedMessageIds') || '[]');
      closedMessageIds.push(activeMessage.id);
      localStorage.setItem('closedMessageIds', JSON.stringify(closedMessageIds));
      setActiveMessage(null);
    }
  };

  const handleAction = () => {
    const user = auth.currentUser;
    if (user && activeMessage) {
      const interactionRef = ref(database, `AlertsView/${activeMessage.id}`);
      push(interactionRef, {
        userId: user.uid,
        email: user.email,
        timestamp: Date.now(),
      }).then(() => {
        console.log('Datos guardados exitosamente en AlertsView');
      }).catch((error) => {
        console.error('Error al guardar los datos en Firebase:', error);
      });
    }
    handleClose();
  };

  if (!activeMessage) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl max-w-md w-full overflow-hidden relative">
        <button 
          onClick={handleClose}
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 bg-white rounded-full p-1"
          aria-label="Close alert"
        >
          <XCircle size={24} />
        </button>
        <img 
          src={activeMessage.imgUrl} 
          alt={activeMessage.title} 
          className="w-full h-48 object-cover"
        />
        <div className="p-6">
          <h2 className="text-2xl font-bold mb-2 text-gray-800">{activeMessage.title}</h2>
          <p className="text-gray-600 mb-4">{activeMessage.message}</p>
          <a
            href={activeMessage.urlAction}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => {
              e.preventDefault();
              handleAction();
              window.open(activeMessage.urlAction, '_blank');
            }}
            className="block w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded text-center transition duration-300"
          >
            {activeMessage.titleButton}
          </a>
        </div>
      </div>
    </div>
  );
};

export default MessageAlerts;