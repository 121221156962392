import React, { useState } from 'react';
import { getDatabase, ref, push, set } from "firebase/database";

export default function UpVipAdmin() {
  const [homeTeamName, setHomeTeamName] = useState('');
  const [homeTeamLogo, setHomeTeamLogo] = useState('');
  const [awayTeamName, setAwayTeamName] = useState('');
  const [awayTeamLogo, setAwayTeamLogo] = useState('');
  const [confidence, setConfidence] = useState('');
  const [odds, setOdds] = useState('');
  const [prediction, setPrediction] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const db = getDatabase();
    const abetRef = ref(db, 'abet');
    const newPredictionKey = push(abetRef).key;
    const newPredictionRef = ref(db, `abetvip/${newPredictionKey}`);

    // Convertir fecha y hora al formato dd/mm/yyyy HH:mm
    const dateTime = new Date(`${date}T${time}`);
    const formattedDate = dateTime.toLocaleDateString('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
    const formattedTime = dateTime.toLocaleTimeString('es-ES', {
      hour: '2-digit',
      minute: '2-digit'
    });

    const data = {
      awayTeam: {
        logo: awayTeamLogo,
        name: awayTeamName
      },
      confidence: parseInt(confidence),
      date: formattedDate, // Corregido para almacenar solo la fecha
      homeTeam: {
        logo: homeTeamLogo,
        name: homeTeamName
      },
      hours: formattedTime, // Corregido para almacenar solo la hora
      odds: parseFloat(odds),
      prediction: prediction,
      state: "pendiente" // Estado por defecto
    };

    set(newPredictionRef, data)
      .then(() => {
        alert("Datos subidos exitosamente.");
        // Limpiar el formulario
        setHomeTeamName('');
        setHomeTeamLogo('');
        setAwayTeamName('');
        setAwayTeamLogo('');
        setConfidence('');
        setOdds('');
        setPrediction('');
        setDate('');
        setTime('');
      })
      .catch((error) => {
        console.error("Error al subir los datos: ", error);
        alert("Error al subir los datos.");
      });
  };

  return (
    <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
      <div className="relative py-3 sm:max-w-xl sm:mx-auto">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-300 to-blue-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
        <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
          <div className="max-w-md mx-auto">
            <div>
              <h1 className="text-2xl font-semibold">Panel de Administración de Pronósticos</h1>
            </div>
            <form onSubmit={handleSubmit} className="divide-y divide-gray-200">
              <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                <div className="flex flex-col">
                  <label className="leading-loose">Equipo Local</label>
                  <input type="text" value={homeTeamName} onChange={(e) => setHomeTeamName(e.target.value)} className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600" placeholder="Nombre del equipo local" required />
                  <input type="text" value={homeTeamLogo} onChange={(e) => setHomeTeamLogo(e.target.value)} className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600 mt-2" placeholder="URL del logo del equipo local" required />
                </div>
                <div className="flex flex-col">
                  <label className="leading-loose">Equipo Visitante</label>
                  <input type="text" value={awayTeamName} onChange={(e) => setAwayTeamName(e.target.value)} className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600" placeholder="Nombre del equipo visitante" required />
                  <input type="text" value={awayTeamLogo} onChange={(e) => setAwayTeamLogo(e.target.value)} className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600 mt-2" placeholder="URL del logo del equipo visitante" required />
                </div>
                <div className="flex flex-col">
                  <label className="leading-loose">Confianza (%)</label>
                  <input type="number" value={confidence} onChange={(e) => setConfidence(e.target.value)} className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600" placeholder="Porcentaje de confianza" required />
                </div>
                <div className="flex flex-col">
                  <label className="leading-loose">Cuota (Odds)</label>
                  <input type="number" step="0.01" value={odds} onChange={(e) => setOdds(e.target.value)} className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600" placeholder="Cuota" required />
                </div>
                <div className="flex flex-col">
                  <label className="leading-loose">Pronóstico</label>
                  <input type="text" value={prediction} onChange={(e) => setPrediction(e.target.value)} className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600" placeholder="Pronóstico" required />
                </div>
                <div className="flex flex-col">
                  <label className="leading-loose">Fecha</label>
                  <input type="date" value={date} onChange={(e) => setDate(e.target.value)} className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600" required />
                </div>
                <div className="flex flex-col">
                  <label className="leading-loose">Hora</label>
                  <input type="time" value={time} onChange={(e) => setTime(e.target.value)} className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600" required />
                </div>
              </div>
              <div className="pt-4 flex items-center space-x-4">
                <button className="bg-blue-500 flex justify-center items-center w-full text-white px-4 py-3 rounded-md focus:outline-none">Subir Pronóstico</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
