import React, { useState } from 'react';

const ButtonTelegram = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleJoinTelegram = () => {
    window.open('https://t.me/deportivospro', '_blank');
  };

  return (
    <div className="relative inline-block">
      {/* Efecto de brillo */}
      <div className={`
        absolute inset-0 
        bg-gradient-to-r from-blue-400/60 to-blue-600/60
        opacity-75 blur-md
        transition-all duration-300 ease-in-out
        ${isHovered ? 'scale-105' : 'scale-95'}
      `} />

      {/* Botón principal */}
      <button
        onClick={handleJoinTelegram}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={`
          relative
          flex items-center justify-center
          bg-gradient-to-r from-blue-500 to-blue-600
          text-white font-semibold
          py-1.5 px-3
          md:py-2 md:px-4
          text-sm md:text-base
          rounded-md
          shadow-md
          transform transition-all duration-200
          hover:shadow-lg hover:shadow-blue-500/30
          active:scale-95
          group
          overflow-hidden
          ${isHovered ? 'ring-2 ring-blue-300 ring-opacity-50' : ''}
        `}
      >
        {/* Efecto de brillo al hover */}
        <div className={`
          absolute inset-0 
          bg-gradient-to-r from-white/10 to-transparent
          transform transition-transform duration-200
          ${isHovered ? 'translate-x-0 opacity-100' : '-translate-x-full opacity-0'}
        `} />

        {/* Icono */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className={`
            w-4 h-4
            md:w-5 md:h-5
            mr-1.5 md:mr-2
            transition-transform duration-200
            ${isHovered ? 'scale-110 rotate-6' : 'scale-100 rotate-0'}
          `}
        >
          <path d="M12 0C5.372 0 0 5.372 0 12c0 6.628 5.372 12 12 12 6.628 0 12-5.372 12-12C24 5.372 18.628 0 12 0zm5.606 7.04l-1.925 9.057c-.147.66-.542.818-1.086.509l-3.012-2.217-1.454 1.4c-.16.16-.296.294-.608.294l.22-3.141 5.724-5.155c.25-.221-.054-.344-.39-.123l-7.07 4.45-3.046-.95c-.661-.203-.674-.661.137-.971l11.873-4.588c.542-.198 1.015.132.842.96z" />
        </svg>

        {/* Texto */}
        <span className={`
          whitespace-nowrap
          transition-all duration-200
          ${isHovered ? 'scale-102' : 'scale-100'}
        `}>
          <span className="hidden sm:inline">Únete a </span>
          Telegram
        </span>

        {/* Flecha */}
        <span className={`
          ml-1 
          transition-all duration-200 ease-in-out
          ${isHovered ? 'translate-x-0 opacity-100' : '-translate-x-1 opacity-0'}
        `}>
          →
        </span>
      </button>
    </div>
  );
};

export default ButtonTelegram;