import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const CustomCarousel = () => {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = React.useState(true);

  const images = [
    {
      src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfNhIIKnO8iV65YNMGghgsCidalpNWFZw02w&s",
      alt: "Imagen 1"
    },
    {
      src: "https://cloudfront-us-east-1.images.arcpublishing.com/elespectador/G37UHSEPXBGSTJ2B5N3UBB5LTA.jpg",
      alt: "Imagen 2"
    },
    {
      src: "https://assets.gamingintelligence.com/wp-content/uploads/sites/4/2020/09/17114009/betplay.jpg",
      alt: "Imagen 3"
    }
  ];

  // Autoplay control
  React.useEffect(() => {
    let interval;
    if (isAutoPlaying) {
      interval = setInterval(() => {
        setCurrentSlide((prev) => (prev + 1) % images.length);
      }, 3000);
    }
    return () => clearInterval(interval);
  }, [isAutoPlaying, images.length]);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="relative w-full max-w-4xl mx-auto group">
      {/* Contenedor principal con altura responsive */}
      <div className="relative h-[200px] sm:h-[300px] md:h-[400px] overflow-hidden rounded-xl">
        {/* Slides */}
        {images.map((image, index) => (
          <div
            key={index}
            className={`absolute w-full h-full transition-opacity duration-500 ease-in-out
              ${currentSlide === index ? 'opacity-100' : 'opacity-0'}`}
          >
            <img
              src={image.src}
              alt={image.alt}
              className="w-full h-full object-cover"
            />
          </div>
        ))}

        {/* Botones de navegación */}
        <button
          onClick={prevSlide}
          className="absolute left-2 top-1/2 -translate-y-1/2 bg-black/30 hover:bg-black/50 text-white p-2 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300"
        >
          <ChevronLeft className="w-6 h-6" />
        </button>
        <button
          onClick={nextSlide}
          className="absolute right-2 top-1/2 -translate-y-1/2 bg-black/30 hover:bg-black/50 text-white p-2 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300"
        >
          <ChevronRight className="w-6 h-6" />
        </button>

        {/* Control de autoplay */}
        <button
          onClick={() => setIsAutoPlaying(!isAutoPlaying)}
          className="absolute bottom-4 right-4 bg-black/30 hover:bg-black/50 text-white px-3 py-1 rounded-full text-sm opacity-0 group-hover:opacity-100 transition-opacity duration-300"
        >
          {isAutoPlaying ? 'Pausar' : 'Reproducir'}
        </button>
      </div>

      {/* Indicadores */}
      <div className="absolute -bottom-6 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {images.map((_, index) => (
          <button
            key={index}
            onClick={() => goToSlide(index)}
            className={`w-2 h-2 rounded-full transition-all duration-300 
              ${currentSlide === index ? 'bg-blue-600 w-4' : 'bg-gray-400'}`}
          >
          </button>
        ))}
      </div>
    </div>
  );
};

export default CustomCarousel;