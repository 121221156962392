import React, { useEffect, useState, useRef } from 'react';
import { Users, Upload, Heart, MessageCircle, Share2, Music } from 'lucide-react';
import { ref, onValue } from 'firebase/database';
import { database } from '../../Config/firebase';
import { FaRegUserCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export default function HomeShort() {
  const [shorts, setShorts] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [progress, setProgress] = useState(0);
  const videoRefs = useRef([]);
  const containerRef = useRef(null);
  const startY = useRef(0);

  useEffect(() => {
    loadShorts();
  }, []);

  const loadShorts = () => {
    const shortsRef = ref(database, 'App/shorts');
    onValue(shortsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const shortsArray = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));
        setShorts(shuffleArray(shortsArray));
      }
    });
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const index = Math.round(containerRef.current.scrollTop / window.innerHeight);
        if (index !== currentIndex) {
          setCurrentIndex(index);
          setIsLiked(false);
        }
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [currentIndex]);

  useEffect(() => {
    videoRefs.current.forEach((video, index) => {
      if (index === currentIndex) {
        video.play();
      } else {
        video.pause();
      }
    });
  }, [currentIndex]);

  const handleTouchStart = (e) => {
    startY.current = e.touches[0].clientY;
  };

  const handleTouchMove = (e) => {
    const currentY = e.touches[0].clientY;
    const diff = currentY - startY.current;

    if (currentIndex === 0 && diff > 100 && !isRefreshing) {
      setIsRefreshing(true);
      loadShorts();
      simulateLoading();
    }
  };

  const simulateLoading = () => {
    setProgress(0);
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          setIsRefreshing(false);
          if (containerRef.current) {
            containerRef.current.scrollTo(0, 0);
          }
          return 0;
        }
        return prevProgress + 10;
      });
    }, 100);
  };

  const handleVideoClick = () => {
    const video = videoRefs.current[currentIndex];
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  };

  const handleLike = () => {
    setIsLiked(!isLiked);
  };

  return (
    <div className="relative h-screen bg-black">
      <div 
        ref={containerRef} 
        className="h-full overflow-y-scroll snap-y snap-mandatory scrollbar-hide"
        style={{ scrollSnapType: 'y mandatory' }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      >
        {isRefreshing && (
          <div className="absolute top-0 left-0 right-0 z-50 h-1 bg-gray-200">
            <div 
              className="h-full bg-red-500 transition-all duration-100 ease-out"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        )}
        {shorts.map((short, index) => (
          <div 
            key={short.id} 
            className="h-screen w-full snap-start relative flex items-center justify-center"
            style={{ scrollSnapAlign: 'start' }}
          >
            <video
              ref={(el) => (videoRefs.current[index] = el)}
              src={short.video}
              className="absolute top-0 left-0 w-full h-full object-cover"
              loop
              playsInline
              autoPlay={index === 0}
              muted={index !== currentIndex}
              onClick={handleVideoClick}
            />
            
            {/* Overlay para información del usuario y música */}
            <div className="absolute bottom-20 left-2 right-16 p-4 bg-gradient-to-t from-black to-transparent">
              <h3 className="text-lg font-semibold text-white">@{short.name}</h3>
              <p className="text-sm text-white mb-2">{short.description}</p>
              {/* <div className="flex items-center text-white">
                <Music size={16} className="mr-2" />
                <p className="text-sm truncate">{short.song || 'Música original'}</p>
              </div> */}
            </div>

            {/* Barra lateral de acciones */}
            <div className="absolute right-2 bottom-24 space-y-4 text-center">
              <button className="block">
                <img src={short.profile} alt="" className="w-12 h-12 rounded-full mb-1 border-2 border-white" />
              </button>
              <button 
                className={`block text-white transition-all duration-300 transform ${isLiked ? 'scale-110' : ''}`} 
                onClick={handleLike}
              >
                <Heart size={32} fill={isLiked ? '#FE2C55' : 'none'} stroke={isLiked ? '#FE2C55' : 'white'} />
                <span className="text-xs block mt-1">{(short.likes || 0) + (isLiked ? 1 : 0)}</span>
              </button>
              <button className="block text-white">
                <MessageCircle size={32} />
                <span className="text-xs block mt-1">{short.comments || 0}</span>
              </button>
              <button className="block text-white">
                <Share2 size={32} />
                <span className="text-xs block mt-1">Compartir</span>
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Barra de navegación inferior */}
      <nav className="fixed bottom-0 left-0 right-0 bg-black bg-opacity-90">
        <div className="flex justify-around items-center h-16">
          <button className="text-white opacity-70 hover:opacity-100 transition-opacity">
            <Users size={24} />
            <span className="text-xs block mt-1">Para ti</span>
          </button>
          <button className="bg-[#FE2C55] text-white rounded-full p-3 transform hover:scale-105 transition-transform">
            <Upload size={24} />
          </button>
          <Link to="/profile">
            <button className="text-white opacity-70 hover:opacity-100 transition-opacity">
              <FaRegUserCircle className="w-6 h-6 mx-auto" />
              <span className="text-xs block mt-1">Perfil</span>
            </button>
          </Link>
        </div>
      </nav>
    </div>
  );
}