import React, { useState, useEffect } from "react";
import { Search, Star, ShoppingBag, X } from "lucide-react";
import {
  getDatabase,
  ref,
  onValue,
  push,
  set,
  update,
} from "firebase/database";
import { getAuth } from "firebase/auth";
import { FaWhatsapp } from "react-icons/fa";
import CircularProgressReward from "../../Utils/CircularProgressReward";

const Button = ({
  children,
  variant = "default",
  size = "default",
  className = "",
  ...props
}) => {
  const baseStyles =
    "font-medium rounded-lg transition-all focus:outline-none focus:ring-2 focus:ring-offset-2";
  const variantStyles = {
    default:
      "bg-indigo-600 text-white hover:bg-indigo-700 focus:ring-indigo-500",
    outline:
      "border border-indigo-600 text-indigo-600 hover:bg-indigo-50 focus:ring-indigo-500",
    ghost: "text-gray-600 hover:bg-gray-100 focus:ring-gray-500",
  };
  const sizeStyles = {
    default: "px-4 py-2 text-sm",
    lg: "px-6 py-3 text-base",
    icon: "p-2",
  };
  return (
    <button
      className={`${baseStyles} ${variantStyles[variant]} ${sizeStyles[size]} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

const Card = ({ children, className = "", ...props }) => (
  <div
    className={`bg-gray-800 rounded-lg shadow-md overflow-hidden transition-all hover:shadow-lg ${className}`}
    {...props}
  >
    {children}
  </div>
);

const CardContent = ({ children, className = "", ...props }) => (
  <div className={`p-4 ${className}`} {...props}>
    {children}
  </div>
);

const Badge = ({ children, className = "", ...props }) => (
  <span
    className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800 ${className}`}
    {...props}
  >
    {children}
  </span>
);

const Input = ({ className = "", ...props }) => (
  <input
    className={`w-full px-4 py-2 border text-black border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 ${className}`}
    {...props}
  />
);

const Store = () => {
  const [products, setProducts] = useState([]);
  const [currentCategory, setCurrentCategory] = useState("All");
  const [purchases, setPurchases] = useState([]);
  const [showPurchases, setShowPurchases] = useState(false);
  const [userBalance, setUserBalance] = useState(0);
  const [modalMessage, setModalMessage] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    const db = getDatabase();

    const storeRef = ref(db, "App/store/products");
    onValue(storeRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const productList = Object.values(data).filter(
          (item, index) => index !== 0 || item !== 0
        );
        setProducts(productList);
      }
    });

    if (user) {
      const userRef = ref(db, `usuarios/${user.uid}/saldo`);
      onValue(userRef, (snapshot) => {
        const saldo = snapshot.val();
        if (saldo !== null) {
          setUserBalance(parseFloat(saldo));
        }
      });

      const purchasesRef = ref(db, `usuarios/${user.uid}/compras`);
      onValue(purchasesRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setPurchases(Object.values(data));
        }
      });
    }
  }, [user]);

  const handlePurchase = (product) => {
    const db = getDatabase();
    const productPrice = parseFloat(product.price);
    const newBalance = parseFloat((userBalance - productPrice).toFixed(2));

    if (newBalance >= 0) {
      const userRef = ref(db, `usuarios/${user.uid}`);
      update(userRef, { saldo: newBalance });

      const purchasesRef = ref(db, `usuarios/${user.uid}/compras`);
      const newPurchaseRef = push(purchasesRef);
      set(newPurchaseRef, {
        ...product,
        purchaseDate: new Date().toISOString(),
      })
        .then(() => {
          setModalMessage(
            `¡Has comprado ${product.name} por ${product.price} monedas!`
          );
          setUserBalance(newBalance);
        })
        .catch((error) => {
          console.error("Error al realizar la compra:", error);
          setModalMessage(
            "Hubo un error al realizar la compra. Por favor, intenta de nuevo."
          );
        });
    } else {
      setModalMessage("No tienes suficiente saldo para realizar esta compra.");
    }
  };

  const closeModal = () => setModalMessage(null);

  const categories = ["All", ...new Set(products.map((p) => p.category))];

  const filteredProducts = products
    .filter((p) => currentCategory === "All" || p.category === currentCategory)
    .filter((p) => p.name.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <div className="bg-gray-900 min-h-screen">
      <nav className="bg-orange-600 shadow-md py-4 fixed w-full z-50">
        <div className="container mx-auto px-4 flex justify-between items-center">
          <a
            href="#"
            className="font-bold text-xl text-white hover:text-indigo-700 transition-colors"
          >
            Dp.Store
          </a>
          <div className="flex items-center space-x-4">
            <div className="text-lg font-semibold text-white">
              <CircularProgressReward />
            </div>
            <Button
              variant="outline"
              size="icon"
              className="border-gray-300 text-gray-700 bg-white"
              onClick={() => setShowPurchases(!showPurchases)}
            >
              <ShoppingBag size={20} />
            </Button>
          </div>
        </div>
      </nav>

      <main className="pt-20 pb-12 px-4">
        <div className="container mx-auto">
          <div className="mb-6">
            <Input
              type="text"
              placeholder="Buscar productos..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="mb-4"
            />
            <div className="flex space-x-2 overflow-x-auto pb-2 scrollbar-hide ">
              {categories.map((category) => (
                <Button
                  key={category}
                  variant={currentCategory === category ? "default" : "ghost"}
                  onClick={() => setCurrentCategory(category)}
                  className="whitespace-nowrap text-sm text-white"
                >
                  {category}
                </Button>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            {filteredProducts.map((product) => (
              <Card key={product.id} className="flex flex-col h-full">
                <img
                  src={product.image}
                  alt={product.name}
                  className="w-full h-48 object-cover"
                />
                <CardContent className="flex-grow flex flex-col justify-between">
                  <div>
                    <Badge className="mb-2">{product.category}</Badge>
                    <h3 className="text-lg font-semibold mb-2">
                      {product.name}
                    </h3>
                    <div className="flex items-center mb-2">
                      <Star
                        className="text-yellow-400 fill-current"
                        size={16}
                      />
                      <span className="ml-1 text-sm text-gray-200">
                        {product.rating}
                      </span>
                    </div>
                    <p className="text-sm text-white mb-4">
                      {product.description}
                    </p>
                  </div>

                  <div className="space-y-2">
                    <Button
                      onClick={() => handlePurchase(product)}
                      className="w-full"
                    >
                      Comprar por {product.price} monedas
                    </Button>
                    <Button
                      variant="outline"
                      className="w-full flex items-center justify-center text-white"
                      onClick={() =>
                        window.open(
                          `https://wa.me/573023580862?text=Hola,%20tengo%20dudas%20sobre%20el%20producto:%0A` +
                            `-%20Nombre:%20${encodeURIComponent(
                              product.name
                            )}%0A` +
                            `-%20Categoría:%20${encodeURIComponent(
                              product.category
                            )}%0A` +
                            `-%20Descripción:%20${encodeURIComponent(
                              product.description
                            )}%0A` +
                            `-%20Precio:%20${encodeURIComponent(
                              product.price
                            )}%20monedas`,
                          "_blank"
                        )
                      }
                    >
                      <FaWhatsapp className="mr-2" />
                      Consultar
                    </Button>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </main>

      {showPurchases && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-900 rounded-lg p-6 w-full max-w-md max-h-[80vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold">Tus Compras</h2>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => setShowPurchases(false)}
              >
                <X size={24} />
              </Button>
            </div>
            {purchases.length > 0 ? (
              purchases.map((purchase, index) => (
                <div key={index} className="mb-4 p-4 border rounded-lg">
                  <h3 className="font-semibold">{purchase.name}</h3>
                  <p>Precio: {purchase.price}</p>
                  <p>
                    Fecha: {new Date(purchase.purchaseDate).toLocaleString()}
                  </p>
                </div>
              ))
            ) : (
              <p>No has realizado ninguna compra aún.</p>
            )}
          </div>
        </div>
      )}

      {modalMessage && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-900 rounded-lg p-6 max-w-sm w-full text-center">
            <p>{modalMessage}</p>
            <Button onClick={closeModal} className="mt-4">
              Cerrar
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Store;
