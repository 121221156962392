import React, { useState } from 'react';
import { User, Mail, Phone, MapPin, Briefcase, Calendar, Edit2 } from 'lucide-react';
import useUserData from '../../Hooks/useUserData';
import FootballLoading from '../../Utils/FootballLoading';

const ProfileSection = ({ icon: Icon, title, value, editable, onEdit }) => {
  return (
    <div className="flex items-center space-x-4 p-4 bg-gray-800 rounded-lg transition-all duration-300 hover:shadow-lg hover:scale-105">
      <Icon className="w-6 h-6 text-blue-400" />
      <div className="flex-grow">
        <h3 className="text-sm font-medium text-gray-400">{title}</h3>
        <p className="text-lg text-white">{value}</p>
      </div>
      {editable && (
        <button
          onClick={onEdit}
          className="p-2 bg-blue-500 rounded-full hover:bg-blue-600 transition-colors duration-300"
        >
          <Edit2 className="w-4 h-4 text-white" />
        </button>
      )}
    </div>
  );
};


const UserProfile = () => {
  const { userData, loading, error } = useUserData();
  

  if (loading) {
    return <FootballLoading />;
  }
  
  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className="max-w-4xl mx-auto p-5 bg-gray-900 rounded-xl shadow-2xl animate-fadeIn pt-11">
      <div className="text-center mb-10">
        <div className="relative inline-block">
          <img
            src="https://avatar.iran.liara.run/public/boy"
            alt="Profile"
            className="w-32 h-32 rounded-full border-4 border-blue-500 animate-pulse"
          />
          <div className="absolute bottom-0 right-0 bg-green-500 w-5 h-5 rounded-full border-2 border-white"></div>
        </div>
        <h1 className="mt-4 text-3xl font-bold text-white">{userData?.name || 'Sin identificar'}</h1>
        <p className="text-blue-400">{userData?.typeU ||  'Sin identificar'}</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <ProfileSection
          icon={Mail}
          title="Correo"
          value={userData?.email || 'Sin identificar'}
          
        />
        <ProfileSection
          icon={Phone}
          title="Codigo"
          value={userData?.code || 'Sin identificar'}
         
        />
        <ProfileSection
          icon={MapPin}
          title="Amigos referidos"
          value={userData?.referralCode || 'Sin identificar'}
         
        />
        <ProfileSection
          icon={Briefcase}
          title="Tipo de cuenta"
          value={userData?.typeU || 'Sin identificar'}
         
        />
      </div>
    </div>
  );
};

export default UserProfile;