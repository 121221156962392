import React, { useState, useEffect } from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import { Search, Calendar, Clock } from "lucide-react";
import useUserData from "../../Hooks/useUserData";
import FootballLoading from "../../Utils/FootballLoading";
import { Helmet } from "react-helmet";

const getCurrentDateTime = () => {
  const today = new Date();
  return today
    .toLocaleString("es-ES", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .replace(",", "");
};

const PredictionCard = ({ prediction }) => {
  const statusColors = {
    ganada: "bg-green-500",
    pendiente: "bg-orange-500",
    perdida: "bg-red-500",
    nula: "bg-white",
  };

  return (
    <div className="relative bg-gray-900 rounded-lg shadow-2xl overflow-hidden transform hover:scale-105 transition-transform duration-300">
      <div
        className={`absolute inset-y-0 right-0 w-2 ${
          statusColors[prediction.state] || "bg-gray-500"
        }`}
      ></div>
      <div className="p-6">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center space-x-4">
            <img
              src={prediction.homeTeam.logo}
              alt={prediction.homeTeam.name}
              className="w-16 h-16 rounded-full border-2 border-white shadow-lg"
            />
            <span className="text-white font-bold text-xl">vs</span>
            <img
              src={prediction.awayTeam.logo}
              alt={prediction.awayTeam.name}
              className="w-16 h-16 rounded-full border-2 border-white shadow-lg"
            />
          </div>
          <div className="bg-yellow-500 text-gray-900 font-bold py-2 px-4 rounded-full text-lg shadow-md">
            {prediction.odds.toFixed(2)}
          </div>
        </div>
        <div className="text-center mb-4">
          <h3 className="text-white font-extrabold text-xl">
            {prediction.homeTeam.name} vs {prediction.awayTeam.name}
          </h3>
          <p className="text-blue-400 text-sm italic">
            {prediction.prediction}
          </p>
        </div>
        <div className="text-center mb-4">
          <div className="flex justify-center items-center text-gray-400 text-sm mt-2">
            <Clock size={18} className="mr-2 text-red-400" />
            <span className="text-white">{prediction.hours}</span>
          </div>
        </div>
        <div className="flex justify-between items-center mb-4">
          <div className="w-2/3 bg-gray-700 rounded-full h-5 shadow-inner">
            <div
              className="bg-green-500 rounded-full h-5 transition-all duration-500"
              style={{ width: `${prediction.confidence}%` }}
            ></div>
          </div>
          <span className="text-green-400 font-bold text-lg">
            {prediction.confidence}%
          </span>
        </div>
        <div className="flex justify-end">
          <button
            onClick={() => {
              const message = `Hola, tengo una duda sobre el pronóstico: ${prediction.homeTeam.name} vs ${prediction.awayTeam.name}, Predicción: ${prediction.prediction}, Probabilidad: ${prediction.confidence}%, Estado ${prediction.state}`;
              const phoneNumber = "+573023580862";
              const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
                message
              )}`;
              window.open(whatsappURL, "_blank");
            }}
            className="bg-blue-600 text-white py-2 px-4 rounded-full shadow-md hover:bg-blue-500 transition-colors duration-300"
          >
            ¿Necesitas ayuda?
          </button>
        </div>
      </div>
    </div>
  );
};

const BetVip = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [predictions, setPredictions] = useState([]);
  const { user } = useUserData(); // Hook para obtener los datos del usuario
  const db = getDatabase();
  const currentDateTime = getCurrentDateTime();

  useEffect(() => {
    const abetVipRef = ref(db, "abetvip");

    // Cargar los pronósticos desde Firebase
    onValue(abetVipRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const loadedPredictions = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));

        // Filtrar pronósticos del día actual
        const todayPredictions = loadedPredictions.filter((prediction) => {
          return prediction.date === currentDateTime.split(" ")[0]; // Asegúrate de que 'date' sea la propiedad correcta
        });

        setPredictions(todayPredictions);
      }
    });
  }, [db, currentDateTime]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredPredictions = predictions.filter(
    (prediction) =>
      prediction.homeTeam.name.toLowerCase().includes(searchTerm) ||
      prediction.awayTeam.name.toLowerCase().includes(searchTerm) ||
      prediction.prediction.toLowerCase().includes(searchTerm)
  );

  const { userData, loading, error } = useUserData();

  if (loading) {
    return <FootballLoading />;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (userData?.typeU !== "Cuenta VIP") {
    return (
      <div className="min-h-screen bg-gray-900 text-white py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto text-center">
          <h1 className="text-4xl font-extrabold mb-6">Acceso VIP Requerido</h1>
          <p className="text-xl text-gray-400 mb-8">
            Esta sección está disponible solo para usuarios VIP. Para ver los
            pronósticos VIP, adquiere una cuenta VIP.
          </p>
          <a
            href="/comprar-vip"
            className="inline-block bg-yellow-500 text-gray-900 font-bold py-3 px-6 rounded-full"
          >
            Comprar Cuenta VIP
          </a>
        </div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Predicciones VIP | Consejos de Apuestas</title>
        <meta
          name="description"
          content="Accede a pronósticos deportivos VIP para mejorar tus apuestas."
        />
      </Helmet>
      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 text-white py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-4xl font-extrabold text-center mb-10">
            Predicciones VIP
          </h1>

          <p className="text-center text-gray-400 mb-10 flex justify-center items-center">
            <Calendar size={20} className="mr-2" />
            <span>{currentDateTime}</span>
          </p>

          <div className="mb-8 max-w-md mx-auto">
            <div className="relative">
              <input
                type="text"
                placeholder="Buscar equipos o pronósticos..."
                className="w-full bg-gray-800 text-white border border-gray-700 rounded-full py-3 px-6 pl-12 focus:outline-none"
                value={searchTerm}
                onChange={handleSearch}
              />
              <Search
                className="absolute left-4 top-3.5 text-gray-400"
                size={20}
              />
            </div>
          </div>

          {filteredPredictions.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {filteredPredictions.map((prediction) => (
                <PredictionCard key={prediction.id} prediction={prediction} />
              ))}
            </div>
          ) : (
            <div className="text-center text-gray-400 mt-8 p-8 bg-gray-800 rounded-lg shadow-lg">
              <p className="text-xl font-semibold mb-2">
                No hay pronósticos disponibles en este momento
              </p>
              <p>
                Vuelve a consultar más tarde o explora nuestro historial de
                predicciones para obtener más información.
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BetVip;
