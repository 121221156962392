import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Home, BookOpen, TrendingUp, MessageSquare, Search, Menu, X, Bell, User } from 'lucide-react';

const HomeBlogs = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState('Todos');
  const [isScrolled, setIsScrolled] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const categorias = ['Todos', 'Fútbol', 'Baloncesto', 'Tenis', 'F1'];
  const articulos = [
    {
      id: 1,
      titulo: 'El Arte de las Apuestas en Vivo',
      extracto: 'Domina las estrategias para apostar con éxito durante los partidos.',
      imagen: 'https://source.unsplash.com/random/800x600?betting',
      categoria: 'Todos',
      autor: 'María González',
      fecha: '15 de abril, 2024'
    },
    {
      id: 2,
      titulo: 'Previa de la Champions League',
      extracto: 'Análisis detallado de los cuartos de final.',
      imagen: 'https://editorial.uefa.com/resources/0246-0e98e0aed53b-074f228dc0a2-1000/ucl_branding.jpeg',
      categoria: 'Fútbol',
      autor: 'Carlos Ramírez',
      fecha: '14 de abril, 2024'
    },
    {
      id: 3,
      titulo: 'La Carrera por los Playoffs de la NBA',
      extracto: '¿Qué equipos asegurarán los últimos puestos?',
      imagen: 'https://source.unsplash.com/random/800x600?basketball',
      categoria: 'Baloncesto',
      autor: 'Ana Martínez',
      fecha: '13 de abril, 2024'
    },
    {
      id: 4,
      titulo: 'Nadal vs Djokovic: El Duelo del Siglo',
      extracto: 'Previa del esperado encuentro en Roland Garros.',
      imagen: 'https://source.unsplash.com/random/800x600?tennis',
      categoria: 'Tenis',
      autor: 'Luis Fernández',
      fecha: '12 de abril, 2024'
    },
    {
      id: 5,
      titulo: 'Revolución en la F1: Nuevas Reglas para 2025',
      extracto: 'Cómo los cambios afectarán a equipos y pilotos.',
      imagen: 'https://source.unsplash.com/random/800x600?formula1',
      categoria: 'F1',
      autor: 'Elena Sánchez',
      fecha: '11 de abril, 2024'
    },
  ];

  const articulosFiltrados = activeCategory === 'Todos' 
    ? articulos 
    : articulos.filter(articulo => articulo.categoria === activeCategory);

  const handleNotificationClick = () => {
    setShowNotification(!showNotification);
  };

  return (
    <>
      <Helmet>
        <title>Análisis Experto y Predicciones Deportivas</title>
        <meta
          name="description"
          content="Sumérgete en el mundo del deporte con análisis de expertos, predicciones precisas y noticias de última hora. DeportivosPro: tu compañero definitivo para el éxito en apuestas y conocimiento deportivo."
        />
        <meta name="keywords" content="Análisis deportivo, predicciones deportivas, consejos de apuestas, deportes en vivo, opiniones de expertos, fútbol, baloncesto, tenis" />
        <link rel="canonical" href="https://deportivospro.online" />
        <meta property="og:title" content="Análisis Experto y Predicciones Deportivas" />
        <meta property="og:description" content="Tu fuente confiable de análisis deportivo, predicciones y noticias de última hora. Optimiza tus apuestas y mantente informado con DeportivosPro." />
        <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/towin-fe4cc.appspot.com/o/logoBlgo%2Ficon512x512preview.png?alt=media&token=00445cc5-773d-43aa-862e-6c437b48bce2" />
        <meta property="og:url" content="https://deportivospro.online" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div className="min-h-screen flex flex-col bg-gradient-to-br from-gray-900 to-blue-900">
        {/* Header */}
        <header className={`fixed w-full z-50 transition-all duration-300 ${isScrolled ? 'bg-gradient-to-br from-gray-900 to-blue-900 shadow-md' : 'bg-transparent'}`}>
          <div className="container mx-auto px-4 py-3 flex items-center justify-between">
            <Link to="/" className="flex items-center space-x-2">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/towin-fe4cc.appspot.com/o/logoBlgo%2Ficon512x512preview.png?alt=media&token=00445cc5-773d-43aa-862e-6c437b48bce2"
                alt="Logo Deporte Total"
                className="w-10 h-10"
              />
              <span className={`font-bold text-xl ${isScrolled ? 'text-white' : 'text-white'}`}>Deporte Total</span>
            </Link>
            <nav className="hidden md:flex space-x-6">
              <Link to="/" className={`hover:text-blue-900 transition ${isScrolled ? 'text-gray-800' : 'text-white'}`}>Inicio</Link>
              <Link to="/articulos" className={`hover:text-blue-900 transition ${isScrolled ? 'text-gray-800' : 'text-white'}`}>Artículos</Link>
              <Link to="/" className={`hover:text-blue-900 transition ${isScrolled ? 'text-gray-800' : 'text-white'}`}>Predicciones</Link>
              <Link to="/contact" className={`hover:text-blue-900 transition ${isScrolled ? 'text-gray-800' : 'text-white'}`}>Contacto</Link>
            </nav>
            <div className="flex items-center space-x-4">
              {/* <button className={`p-2 rounded-full hover:bg-gray-200 transition ${isScrolled ? 'text-gray-800' : 'text-white'}`} aria-label="Buscar">
                <Search size={20} />
              </button> */}
              {/* <button 
                className={`p-2 rounded-full hover:bg-gray-200 transition ${isScrolled ? 'text-gray-800' : 'text-white'}`}
                onClick={handleNotificationClick}
                aria-label="Notificaciones"
              >
                <Bell size={20} />
              </button> */}
              {/* <button className={`p-2 rounded-full hover:bg-gray-200 transition ${isScrolled ? 'text-gray-800' : 'text-white'}`} aria-label="Perfil de usuario">
                <User size={20} />
              </button> */}
              <button 
                className={`md:hidden p-2 rounded-full hover:bg-gray-300 transition bg-white ${isScrolled ? 'text-black' : 'text-black'}`}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                aria-label="Menú"
              >
                {isMenuOpen ? <X size={20} /> : <Menu size={20} />}
              </button>
            </div>
          </div>
        </header>

        {/* Notificación */}
        {showNotification && (
          <div className="fixed top-20 right-4 bg-white shadow-lg rounded-lg p-4 z-50 w-80 animate-fade-in-down">
            <h3 className="font-bold text-lg mb-2">Últimas Noticias</h3>
            <ul className="space-y-2">
              <li className="text-sm">🏆 Real Madrid gana la Champions League</li>
              <li className="text-sm">🏀 Los Lakers se clasifican para los playoffs</li>
              <li className="text-sm">🎾 Nadal anuncia su regreso a las pistas</li>
            </ul>
          </div>
        )}

        {/* Menú Móvil */}
        {isMenuOpen && (
          <div className="md:hidden fixed top-16 left-0 right-0 bg-white shadow-lg z-40 animate-fade-in-down">
            <nav className="container mx-auto px-4 py-2">
              <Link to="/" className="block py-2 text-gray-800 hover:bg-gray-100">Inicio</Link>
              <Link to="/articulos" className="block py-2 text-gray-800 hover:bg-gray-100">Artículos</Link>
              <Link to="/predicciones" className="block py-2 text-gray-800 hover:bg-gray-100">Predicciones</Link>
              <Link to="/contacto" className="block py-2 text-gray-800 hover:bg-gray-100">Contacto</Link>
            </nav>
          </div>
        )}

        {/* Hero Section */}
        <section className="bg-gradient-to-r from-blue-600 to-indigo-700 text-white pt-32 pb-20">
          <div className="container mx-auto px-4 text-center">
            <h1 className="text-4xl md:text-6xl font-bold mb-4 animate-fade-in-down">Bienvenido a Deporte Total</h1>
            <p className="text-xl mb-8 animate-fade-in-up">Tu portal de análisis experto, predicciones y noticias deportivas de última hora.</p>
            <Link to="/suscribirse" className="bg-white text-blue-700 px-8 py-3 rounded-full font-bold hover:bg-gray-100 transition transform hover:scale-105 inline-block animate-pulse shadow-lg">
              Obtén Acceso Premium
            </Link>
          </div>
        </section>

        {/* Filtro de Categorías */}
        <div className="sticky top-16 bg-white shadow z-30">
          <div className="container mx-auto px-4 py-4 overflow-x-auto">
            <div className="flex space-x-4">
              {categorias.map(categoria => (
                <button
                  key={categoria}
                  className={`px-4 py-2 rounded-full transition transform hover:scale-105 ${
                    activeCategory === categoria ? 'bg-blue-600 text-white shadow-md' : 'bg-gray-200 text-gray-700 hover:bg-blue-500 hover:text-white'
                  }`}
                  onClick={() => setActiveCategory(categoria)}
                >
                  {categoria}
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* Contenido Principal */}
        <main className="flex-grow container mx-auto px-4 py-8">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {articulosFiltrados.map(articulo => (
              <article key={articulo.id} className="bg-white rounded-lg shadow-md overflow-hidden transition transform hover:scale-105 hover:shadow-xl">
                <img src={articulo.imagen} alt={articulo.titulo} className="w-full h-48 object-cover" />
                <div className="p-6">
                  <h2 className="text-2xl font-semibold mb-2 text-gray-800 hover:text-blue-600">{articulo.titulo}</h2>
                  <p className="text-gray-600 mb-4">{articulo.extracto}</p>
                  <div className="flex justify-between items-center text-sm text-gray-500 mb-4">
                    <span>{articulo.autor}</span>
                    <time dateTime={articulo.fecha}>{articulo.fecha}</time>
                  </div>
                  <Link to={`/articulo/${articulo.id}`} className="text-blue-600 hover:text-blue-800 font-semibold inline-flex items-center transition-colors duration-200">
                    Leer Más <span className="ml-1 transition-transform duration-200 transform group-hover:translate-x-1">→</span>
                  </Link>
                </div>
              </article>
            ))}
          </div>
        </main>

        {/* Sección de Newsletter */}
        <section className="bg-gradient-to-r from-green-400 to-blue-500 text-white py-16">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-3xl font-bold mb-4">Mantente Siempre Informado</h2>
            <p className="mb-8 text-lg">Suscríbete a nuestro boletín para recibir análisis exclusivos y predicciones.</p>
            <form className="max-w-md mx-auto flex" onSubmit={(e) => e.preventDefault()}>
              <input 
                type="email" 
                placeholder="Tu dirección de correo" 
                className="flex-grow px-4 py-2 rounded-l-full focus:outline-none focus:ring-2 focus:ring-blue-600 text-gray-800"
                aria-label="Dirección de correo electrónico"
                required
              />
              <button type="submit" className="bg-blue-600 text-white px-6 py-2 rounded-r-full hover:bg-blue-700 transition transform hover:scale-105 shadow-lg">
                Suscribirse
              </button>
            </form>
          </div>
        </section>


        {/* Footer */}
        <footer className="bg-gray-800 text-white py-8">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-between">
              <div className="w-full md:w-1/4 mb-6 md:mb-0">
                <h3 className="text-lg font-semibold mb-4">About Us</h3>
                <p className="text-gray-400">Sports Insider: Your trusted source for in-depth sports analysis and predictions.</p>
              </div>
              <div className="w-full md:w-1/4 mb-6 md:mb-0">
                <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
                <ul className="space-y-2">
                  <li><Link to="/about" className="text-gray-400 hover:text-white">About</Link></li>
                  <li><Link to="/contact" className="text-gray-400 hover:text-white">Contact</Link></li>
                  <li><Link to="/privacy" className="text-gray-400 hover:text-white">Privacy Policy</Link></li>
                  <li><Link to="/terms" className="text-gray-400 hover:text-white">Terms of Service</Link></li>
                </ul>
              </div>
              <div className="w-full md:w-1/4 mb-6 md:mb-0">
                <h3 className="text-lg font-semibold mb-4">Follow Us</h3>
                <div className="flex space-x-4">
                  <a href="#" className="text-gray-400 hover:text-white"><i className="fab fa-facebook"></i></a>
                  <a href="#" className="text-gray-400 hover:text-white"><i className="fab fa-twitter"></i></a>
                  <a href="#" className="text-gray-400 hover:text-white"><i className="fab fa-instagram"></i></a>
                </div>
              </div>
              <div className="w-full md:w-1/4">
                <h3 className="text-lg font-semibold mb-4">Contact Us</h3>
                <p className="text-gray-400">Email: info@sportsinsider.com</p>
                <p className="text-gray-400">Phone: +1 (555) 123-4567</p>
              </div>
            </div>
            <div className="mt-8 pt-8 border-t border-gray-700 text-center text-gray-400">
              <p>&copy; 2024 Sports Insider. All rights reserved.</p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default HomeBlogs;