import React from 'react';

const Footer = () => {
  const handleContactWhatsApp = () => {
    const phoneNumber = '+573023580862';
    const message = 'Quiero entrar en el VIP para obtener más pronósticos y ganar más';
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappLink, '_blank');
  };

  return (
    <footer className="bg-gray-800 text-white py-6">
      <div className="container mx-auto text-center">
        <p className="mb-4">¿Quieres más pronósticos?</p>
        <button
          onClick={handleContactWhatsApp}
          className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
        >
          Contactar por WhatsApp
        </button>
        <div className="flex justify-center mt-6 space-x-4">
          <a
            href="https://www.facebook.com/profile.php?id=61563265143068"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-800"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path d="M22.675 0H1.325C.593 0 0 .593 0 1.325v21.351C0 23.407.593 24 1.325 24h11.495v-9.294H9.691V11.31h3.129V8.765c0-3.097 1.891-4.785 4.653-4.785 1.324 0 2.463.099 2.795.142v3.24h-1.918c-1.504 0-1.795.715-1.795 1.764v2.313h3.588l-.467 3.396h-3.121V24h6.116C23.407 24 24 23.407 24 22.676V1.325C24 .593 23.407 0 22.675 0z" />
            </svg>
          </a>
          <a
            href="https://www.instagram.com/deportivospro"
            target="_blank"
            rel="noopener noreferrer"
            className="text-pink-600 hover:text-pink-800"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path d="M12 2.163c3.204 0 3.584.012 4.85.07 1.366.062 2.633.334 3.608 1.31.975.975 1.248 2.242 1.31 3.608.058 1.267.07 1.647.07 4.85s-.012 3.584-.07 4.85c-.062 1.366-.334 2.633-1.31 3.608-.975.975-2.242 1.248-3.608 1.31-1.267.058-1.647.07-4.85.07s-3.584-.012-4.85-.07c-1.366-.062-2.633-.334-3.608-1.31-.975-.975-1.248-2.242-1.31-3.608-.058-1.267-.07-1.647-.07-4.85s.012-3.584.07-4.85c.062-1.366.334-2.633 1.31-3.608.975-.975 2.242-1.248 3.608-1.31 1.267-.058 1.647-.07 4.85-.07zm0-2.163C8.756 0 8.33.012 7.052.07 5.771.129 4.501.401 3.42 1.482 2.338 2.563 2.066 3.833 2.007 5.115.949 5.852.001 8.756.001 12s.948 6.148 2.007 6.885c.059 1.282.331 2.552 1.412 3.633 1.081 1.081 2.351 1.353 3.633 1.412 1.283.059 1.708.07 4.947.07s3.664-.012 4.947-.07c1.282-.059 2.552-.331 3.633-1.412 1.081-1.081 1.353-2.351 1.412-3.633.059-1.283.07-1.708.07-4.947s-.012-3.664-.07-4.947c-.059-1.282-.331-2.552-1.412-3.633-1.081-1.081-2.351-1.353-3.633-1.412C15.664.012 15.239 0 12 0zm0 5.838a6.162 6.162 0 1 0 0 12.324 6.162 6.162 0 0 0 0-12.324zm0 10.162a3.998 3.998 0 1 1 0-7.996 3.998 3.998 0 0 1 0 7.996zm6.406-11.845a1.44 1.44 0 1 1 0-2.881 1.44 1.44 0 0 1 0 2.881z" />
            </svg>
          </a>
        </div>
        <div className="mt-6">
          <p>Contacto: +573023580862</p>
          <p>Email: deportivospro@gmail.com</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
