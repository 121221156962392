import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFound from "./App/Pages/NotFound";
import ProtectedRoute from "./Config/ProtectedRoute";
import Home from "./App/Home/Home";
import Login from "./App/login/Login"; // Asegúrate de que el archivo se llame Login.js o Login.jsx
import Register from "./App/sigunp/Register"; // Verifica que el archivo Register esté en la carpeta Signup
import ComprarVip from "./App/Vip/ComprarVip";
import ForgotPassword from "./App/Profile/ForgotPassword";
import BetFree from "./App/Free/BetFree";
import PanelAdmin from "./Admin/PanelAdmin";
import UpVipAdmin from "./Admin/UpVipAdmin";

import HomeBlogs from "./App/Blogs/HomeBlogs";
import Marcadores from "./App/Home/Marcadores";
import ContactForm from "./App/Contact/ContactForm";
import HomeProfile from "./App/Shorts/Profile/HomeProfile";
import SuperPromos from "./App/promos/SuperPromos";
import ApuestasEnVivo from "./App/Blogs/articles/ApuestasEnVivo";
import WalletView from "./App/Wallet/WalletView";
import Store from "./App/Store/Store";
import useAppSecurity from "./Hooks/useAppSecurity";
import { showMobileOnlyMessage } from "./Config/onlyPhone";
import UserProfile from "./App/Profile/UserProfile";

function App() {
  useEffect(() => {
    // Llamar a la función cuando el componente se monta
    // 1087 790 916
    
    
  }, []);
  useAppSecurity();
  // showMobileOnlyMessage();

  
  return (
    <Router>
      <Routes> 
        <Route path="/" element={<Home />} />
        {/* <Route path="/" element={<ProtectedRoute element={<Home />} />} /> */}
        <Route path="/tips-free" element={<ProtectedRoute element={<BetFree />} />} />
        <Route path="/home" element={<ProtectedRoute element={<Home />} />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/comprar-vip" element={<ComprarVip />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/marcadores-en-vivo" element={<Marcadores />} />
        <Route path="/contact" element={<ContactForm />} />
        <Route path="/profile" element={<UserProfile />} /> {/* Ruta al perfil */}
        <Route path="/promociones-vip" element={<SuperPromos />} /> {/* Ruta al perfil */}
        <Route path="/store" element={<Store />} /> {/* Ruta al perfil */}

        <Route path="/wallet" element={<WalletView />} /> {/* Ruta al perfil */}
        {/* rutas blogs */}
        <Route path="/blogs" element={<HomeBlogs />} />
        <Route path="/articulos/apuestas-en-vivo" element={<ApuestasEnVivo />} />

        {/* <Route path="/article/:slug" component={ArticlePage} /> */}
        <Route path="/app-admin" element={<ProtectedRoute element={<PanelAdmin />} />} />
        <Route path="/app-admin-vip" element={<ProtectedRoute element={<UpVipAdmin />} />} />
        {/* Ruta para manejar páginas no encontradas */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
